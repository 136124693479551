import React from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const CallToAction: React.FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Paper
          sx={{
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            p: { xs: 4, md: 8 },
            textAlign: 'center',
            borderRadius: 4,
          }}
        >
          <Typography variant="h4" component="h2" gutterBottom>
            Ready to Transform Your Business?
          </Typography>
          <Typography variant="h6" sx={{ mb: 4, opacity: 0.9 }}>
            Get started with Algofy.ai today and unlock the power of AI for your organization.
          </Typography>
          <Button
            component={Link}
            to="/contact"
            variant="contained"
            size="large"
            sx={{
              backgroundColor: 'common.white',
              color: 'primary.main',
              '&:hover': {
                backgroundColor: 'grey.100',
              },
            }}
          >
            Contact Us Now
          </Button>
        </Paper>
      </motion.div>
    </Box>
  );
};

export default CallToAction;