import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LeadScoring from './components/LeadScoring'
import VoiceMail from './components/VoiceMail'
import ChatBot from './components/ChatBot'

const ServicesPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ pb: 8 }}>
      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          gutterBottom
          sx={{ mb: 6, pt: 4 }}
        >
          Our Services
        </Typography>
        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={12}>
            <Box onClick={() => navigate('/services/chat-bot')} sx={{ cursor: 'pointer' }}>
              <ChatBot />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box onClick={() => navigate('/services/lead-scoring')} sx={{ cursor: 'pointer' }}>
              <LeadScoring />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box onClick={() => navigate('/services/voice-mail')} sx={{ cursor: 'pointer' }}>
              <VoiceMail />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ServicesPage;