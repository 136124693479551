import React, { useState, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  MenuItem,
  Paper,
  MenuList,
  Collapse,
  ListItemButton
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

interface NavChild {
  title: string;
  path: string;
}

const serviceItems: NavChild[] = [
  { title: 'Chat Bot Live Chat', path: '/services/chat-bot-live-chat'},
  { title: 'Chat Bot', path: '/services/chat-bot' },
  { title: 'Lead Scoring', path: '/services/lead-scoring' },
  { title: 'Voice Mail', path: '/services/voice-mail' }
];

export const Navbar: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileServicesOpen, setMobileServicesOpen] = useState(false);
  const [desktopServicesOpen, setDesktopServicesOpen] = useState(false);
  const servicesAnchorRef = useRef<HTMLAnchorElement>(null);

  // Desktop hover handlers
  const handleServicesMouseEnter = () => {
    setDesktopServicesOpen(true);
  };

  const handleServicesMouseLeave = () => {
    setTimeout(() => {
      setDesktopServicesOpen(false);
    }, 300);
  };

  // Mobile handlers
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMobileServicesClick = () => {
    setMobileServicesOpen(!mobileServicesOpen);
  };

  const handleMenuItemClick = () => {
    setMobileOpen(false);
    setMobileServicesOpen(false);
    setDesktopServicesOpen(false);
  };

  // Desktop Menu Component
  const DesktopMenu = () => (
    <Box sx={{ 
      flexGrow: 1, 
      display: { xs: 'none', md: 'flex' }, 
      justifyContent: 'flex-end'
    }}>
      <Button component={Link} to="/" color="inherit">
        Home
      </Button>
      <Box
        onMouseEnter={handleServicesMouseEnter}
        onMouseLeave={handleServicesMouseLeave}
        sx={{ 
          position: 'relative',
          '& .MuiPaper-root': {
            mt: 0,
            pt: 2,
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            height: '20px',
            background: 'transparent'
          }
        }}
      >
        <Button
          ref={servicesAnchorRef}
          component={Link}
          to="/services"
          color="inherit"
          sx={{ mx: 1 }}
        >
          Services
        </Button>
        {desktopServicesOpen && (
          <Paper
            onMouseEnter={() => setDesktopServicesOpen(true)}
            onMouseLeave={handleServicesMouseLeave}
            sx={{
              position: 'absolute',
              top: '100%',
              right: 0,
              minWidth: 200,
              zIndex: (theme) => theme.zIndex.appBar + 1
            }}
          >
            <MenuList>
              {serviceItems.map((child) => (
                <MenuItem
                  key={child.title}
                  component={Link}
                  to={child.path}
                  onClick={handleMenuItemClick}
                >
                  {child.title}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        )}
      </Box>
      <Button 
        component={Link} 
        to="/contact" 
        color="inherit"
        sx={{ mr: 2 }}
      >
        Contact
      </Button>
    </Box>
  );

  // Mobile Menu Component
  const MobileMenu = () => (
    <Drawer
      variant="temporary"
      anchor="right"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: 'block', md: 'none' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 240,
          zIndex: (theme) => theme.zIndex.appBar - 1,
        },
      }}
    >
      <Box sx={{ pt: 7 }}>
        <List>
          <ListItem disablePadding onClick={handleMenuItemClick}>
            <ListItemButton component={Link} to="/">
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={handleMobileServicesClick}>
              <ListItemText primary="Services" />
              {mobileServicesOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={mobileServicesOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {serviceItems.map((item) => (
                <ListItem 
                  key={item.title} 
                  disablePadding 
                  onClick={handleMenuItemClick}
                  sx={{ pl: 4 }}
                >
                  <ListItemButton 
                    component={Link} 
                    to={item.path}
                    sx={{ py: 1 }}
                  >
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>

          <ListItem disablePadding onClick={handleMenuItemClick}>
            <ListItemButton component={Link} to="/contact">
              <ListItemText primary="Contact" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        {/* Logo */}
        <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Box
              component="img"
              src="/images/logo.png"
              srcSet="/images/logo.png 1x, /images/logo@2x.png 2x"
              alt="Algofy"
              sx={{
                height: { xs: '30px', md: '40px' },
                width: 'auto',
                display: 'block'
              }}
            />
          </Link>
        </Box>

        {/* Desktop Menu */}
        <DesktopMenu />

        {/* Mobile Menu Icon */}
        <IconButton
          sx={{ display: { xs: 'flex', md: 'none' }, ml: 'auto' }}
          onClick={handleDrawerToggle}
          color="inherit"
          aria-label="open menu"
        >
          <MenuIcon />
        </IconButton>

        {/* Mobile Menu */}
        <MobileMenu />
      </Toolbar>
    </AppBar>
  );
}; 