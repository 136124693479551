import React, { useState } from 'react';
import { TextField, Button, Box, MenuItem } from '@mui/material';
import ApplicationLayout from './components/ApplicationLayout';
import { useFormSubmission } from '../../hooks/useFormSubmission';
import 'react-toastify/dist/ReactToastify.css';

const crmOptions = ['HubSpot', 'Salesforce', 'Pipedrive', 'Other'];
const goalOptions = [
  'Lead Quality for Booking',
  'Lead Scoring for Conversion',
  'Program Cancellation Model',
  'Other'
];

const LeadScoringApplication: React.FC = () => {
  const [formData, setFormData] = useState({
    companySize: '',
    currentCrm: '',
    goals: '',
    leadsPerMonth: '',
    website: '',
    contactName: '',
    email: '',
  });

  const { isSubmitting, handleSubmit } = useFormSubmission();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await handleSubmit(formData, {
      endpoint: 'https://00lioerind.execute-api.us-east-1.amazonaws.com/prod',
      successMessage: "Application submitted successfully! We'll review and contact you soon.",
      errorMessage: "Failed to submit application. Please try again.",
      onSuccess: () => setFormData({
        companySize: '',
        currentCrm: '',
        goals: '',
        leadsPerMonth: '',
        website: '',
        contactName: '',
        email: '',
      })
    });
  };

  return (
    <ApplicationLayout title="Lead Scoring Integration Application">
      <Box component="form" onSubmit={onSubmit} noValidate>
        <TextField
          required
          fullWidth
          margin="normal"
          label="Company Size"
          name="companySize"
          type="number"
          value={formData.companySize}
          onChange={handleChange}
        />
        <TextField
          required
          select
          fullWidth
          margin="normal"
          label="Current CRM"
          name="currentCrm"
          value={formData.currentCrm}
          onChange={handleChange}
        >
          {crmOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          required
          select
          fullWidth
          margin="normal"
          label="Primary Goal"
          name="goals"
          value={formData.goals}
          onChange={handleChange}
        >
          {goalOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          required
          fullWidth
          margin="normal"
          label="Leads per Month"
          name="leadsPerMonth"
          type="number"
          value={formData.leadsPerMonth}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          label="Company Website"
          name="website"
          value={formData.website}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          label="Contact Name"
          name="contactName"
          value={formData.contactName}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          label="Email Address"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
        />
        <Button
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          disabled={isSubmitting}
          sx={{ mt: 3 }}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Application'}
        </Button>
      </Box>
    </ApplicationLayout>
  );
};

export default LeadScoringApplication;

