import React from 'react';
import { Box, Container, Typography, Paper } from '@mui/material';
import { motion } from 'framer-motion';

interface ApplicationLayoutProps {
  title: string;
  children: React.ReactNode;
}

const ApplicationLayout: React.FC<ApplicationLayoutProps> = ({ title, children }) => {
  return (
    <Box>
      <Container maxWidth="md">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            component="h1"
            variant="h3"
            align="center"
            gutterBottom
            sx={{ mb: 6, pt: 4 }}
          >
            {title}
          </Typography>
          <Paper sx={{ p: 4 }}>
            {children}
          </Paper>
        </motion.div>
      </Container>
    </Box>
  );
};

export default ApplicationLayout;
