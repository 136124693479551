import React from 'react';
import { Box, Container, Typography, Button, Stack } from '@mui/material';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const HeroSection: React.FC = () => {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        pt: { xs: 4, sm: 8, md: 12 },
        pb: { xs: 8, sm: 12, md: 16 },
      }}
    >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
            sx={{ fontWeight: 'bold' }}
          >
            Transform Your Business with AI
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
            sx={{ mb: 4 }}
          >
            Harness the power of artificial intelligence to optimize your operations,
            enhance decision-making, and drive innovation.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="large"
              component={Link}
              to="/services"
            >
              Get Started
            </Button>
          </Stack>
        </motion.div>
      </Container>
    </Box>
  );
};

export default HeroSection; 