import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Box, 
  MenuItem, 
  FormControlLabel, 
  Checkbox,
  Select,
  Chip,
  OutlinedInput,
  InputLabel,
  FormControl
} from '@mui/material';
import ApplicationLayout from './components/ApplicationLayout';
import { useFormSubmission } from '../../hooks/useFormSubmission';
import 'react-toastify/dist/ReactToastify.css';

const dataFormatOptions = ['Text', 'Audio', 'Video', 'Multiple Formats'];
const usageOptions = ['Customer Facing', 'Internal Use', 'Both'];
const integrationOptions = ['Slack', 'Web', 'Chat Bubble', 'Other'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ChatBotApplication: React.FC = () => {
  const [formData, setFormData] = useState({
    hasPrivateData: false,
    dataFormat: [] as string[],
    integrationEnvironment: [] as string[],
    usage: '',
    website: '',
    contactName: '',
    email: '',
  });

  const { isSubmitting, handleSubmit } = useFormSubmission();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleDataFormatChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      dataFormat: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const handleIntegrationChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      integrationEnvironment: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await handleSubmit(formData, {
      endpoint: 'https://00lioerind.execute-api.us-east-1.amazonaws.com/prod',
      successMessage: "Application submitted successfully! We'll review and contact you soon.",
      errorMessage: "Failed to submit application. Please try again.",
      onSuccess: () => setFormData({
        hasPrivateData: false,
        dataFormat: [],
        integrationEnvironment: [],
        usage: '',
        website: '',
        contactName: '',
        email: '',
      })
    });
  };

  return (
    <ApplicationLayout title="Custom Chat Bot Application">
      <Box component="form" onSubmit={onSubmit} noValidate>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.hasPrivateData}
              onChange={handleChange}
              name="hasPrivateData"
            />
          }
          label="I've privately owned data"
        />
        
        <FormControl fullWidth margin="normal">
          <InputLabel id="data-format-label">Data Format</InputLabel>
          <Select
            required
            multiple
            labelId="data-format-label"
            name="dataFormat"
            value={formData.dataFormat}
            onChange={handleDataFormatChange}
            input={<OutlinedInput label="Data Format" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {(selected as string[]).map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {dataFormatOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
        <FormControl fullWidth margin="normal">
          <InputLabel id="integration-environment-label">Integration Environment</InputLabel>
          <Select
            required
            multiple
            labelId="integration-environment-label"
            name="integrationEnvironment"
            value={formData.integrationEnvironment}
            onChange={handleIntegrationChange}
            input={<OutlinedInput label="Integration Environment" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {(selected as string[]).map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {integrationOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          required
          select
          fullWidth
          margin="normal"
          label="Usage Type"
          name="usage"
          value={formData.usage}
          onChange={handleChange}
        >
          {usageOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          required
          fullWidth
          margin="normal"
          label="Company Website"
          name="website"
          value={formData.website}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          label="Contact Name"
          name="contactName"
          value={formData.contactName}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          label="Email Address"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
        />
        <Button
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          disabled={isSubmitting}
          sx={{ mt: 3 }}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Application'}
        </Button>
      </Box>
    </ApplicationLayout>
  );
};

export default ChatBotApplication; 