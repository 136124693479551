import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Box } from '@mui/material';
import { theme } from './theme/theme';
import { Navbar } from './components/Layout/Navbar';
import Footer from './components/Layout/Footer';
import { AppRoutes } from './routes';
import { ToastContainer } from 'react-toastify';
import { useUTMTracking } from './hooks/useUTMTracking';
import 'react-toastify/dist/ReactToastify.css';

function AppContent() {
  useUTMTracking();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: 'background.default',
      }}
    >
      <Navbar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: { xs: 8, sm: 9 },
        }}
      >
        <AppRoutes />
      </Box>
      <Footer />
    </Box>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App; 