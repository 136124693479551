import React from 'react';
import { Box, Typography, Card, CardContent, Avatar, Grid } from '@mui/material';
import { FormatQuote } from '@mui/icons-material';
import { motion } from 'framer-motion';

const testimonials = [
  {
    name: 'Sarah Johnson',
    position: 'CTO, TechCorp',
    content: 'Algofy.ai transformed our data analytics capabilities. Their solutions helped us reduce decision-making time by 60%.',
    avatar: 'SJ',
  },
  {
    name: 'Michael Chen',
    position: 'Data Science Lead, InnovateCo',
    content: 'The expertise and support from the Algofy.ai team have been invaluable. They truly understand AI implementation.',
    avatar: 'MC',
  },
  {
    name: 'Emma Williams',
    position: 'CEO, DataDrive',
    content: 'Working with Algofy.ai has been a game-changer for our business. Their AI solutions are both powerful and practical.',
    avatar: 'EW',
  },
];

const Testimonials: React.FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Typography
        component="h2"
        variant="h3"
        align="center"
        color="text.primary"
        gutterBottom
        sx={{ mb: 6 }}
      >
        What Our Clients Say
      </Typography>
      <Grid container spacing={4}>
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} md={4} key={testimonial.name}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <FormatQuote
                    sx={{ fontSize: 40, color: 'primary.main', opacity: 0.5 }}
                  />
                  <Typography variant="body1" paragraph>
                    "{testimonial.content}"
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
                      {testimonial.avatar}
                    </Avatar>
                    <Box>
                      <Typography variant="subtitle1">{testimonial.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {testimonial.position}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Testimonials; 