import React from 'react';
import { Paper, Typography, Box, Link, Stack } from '@mui/material';
import { Email, Phone, LocationOn, LinkedIn } from '@mui/icons-material';
import XIcon from '@mui/icons-material/X';

const ContactInfo: React.FC = () => {
  return (
    <Paper sx={{ p: 4, height: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Get in Touch
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Stack spacing={3}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Email sx={{ mr: 2, color: 'primary.main' }} />
            <Link href="mailto:support@algofy.ai" color="inherit">
              support@algofy.ai
            </Link>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Phone sx={{ mr: 2, color: 'primary.main' }} />
            <Link href="tel:+15126664243" color="inherit">
              (512) 666-4243
            </Link>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LocationOn sx={{ mr: 2, color: 'primary.main' }} />
            <Typography>
              10900 Research Blvd, Ste 160C
              <br />
              Austin, TX 78759
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Link 
              href="https://www.linkedin.com/company/handl-digital" 
              target="_blank"
              rel="noopener noreferrer" 
              color="primary"
            >
              <LinkedIn fontSize="large" />
            </Link>
            <Link 
              href="https://x.com/handldigital" 
              target="_blank"
              rel="noopener noreferrer" 
              color="primary"
            >
              <XIcon fontSize="large" />
            </Link>
          </Box>
        </Stack>
      </Box>
    </Paper>
  );
};

export default ContactInfo; 