import React from 'react';
import { Paper, Typography, Box, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { CheckCircle, Timeline } from '@mui/icons-material';
import { motion } from 'framer-motion';

const features = [
  'AI-powered lead qualification',
  'Seamless CRM integration',
  'Real-time scoring updates',
  'Custom scoring models',
  'Detailed analytics dashboard'
];

const LeadScoring: React.FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <Paper sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Timeline sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
          <Typography variant="h4" component="h2">
            Lead Scoring Integration
          </Typography>
        </Box>
        
        <Typography variant="body1" paragraph>
          Transform your lead qualification process with our AI-powered lead scoring system. 
          Seamlessly integrate with your existing forms and CRM to automatically identify and 
          prioritize your most promising leads.
        </Typography>

        <List>
          {features.map((feature) => (
            <ListItem key={feature}>
              <ListItemIcon>
                <CheckCircle color="primary" />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>

        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button
            variant="contained"
            size="large"
            href="/services/lead-scoring"
            sx={{ minWidth: 200 }}
          >
            Get Started
          </Button>
        </Box>
      </Paper>
    </motion.div>
  );
};

export default LeadScoring; 