import React, { useState } from 'react';
import { Paper, TextField, Button, Box } from '@mui/material';
import { useFormSubmission } from '../../../hooks/useFormSubmission';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: '',
  });

  const { isSubmitting, handleSubmit } = useFormSubmission();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await handleSubmit(formData, {
      endpoint: 'https://00lioerind.execute-api.us-east-1.amazonaws.com/prod',
      successMessage: "Thank you for your message. We'll get back to you soon!",
      errorMessage: "Failed to send message. Please try again.",
      onSuccess: () => setFormData({ name: '', email: '', company: '', message: '' })
    });
  };

  return (
    <Paper sx={{ p: 4 }}>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <TextField
          required
          fullWidth
          margin="normal"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Company"
          name="company"
          value={formData.company}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          label="Message"
          name="message"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
        />
        <Button
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          disabled={isSubmitting}
          sx={{ mt: 3 }}
        >
          {isSubmitting ? 'Sending...' : 'Send Message'}
        </Button>
      </Box>
    </Paper>
  );
};

export default ContactForm; 