import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import ContactForm from './components/ContactForm';
import ContactInfo from './components/ContactInfo';
import { motion } from 'framer-motion';

const ContactPage: React.FC = () => {
  return (
    <Box sx={{ pb: 8 }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            gutterBottom
            sx={{ mb: 6, pt: 4 }}
          >
            Contact Us
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>
              <ContactForm />
            </Grid>
            <Grid item xs={12} md={5}>
              <ContactInfo />
            </Grid>
          </Grid>
        </motion.div>
      </Container>
    </Box>
  );
};

export default ContactPage;