import React from 'react';
import { Box, Container, Typography, Paper } from '@mui/material';
import { motion } from 'framer-motion';

const TermsOfService: React.FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Paper sx={{ p: 4 }}>
            <Typography variant="h3" gutterBottom align="center" sx={{ mb: 6 }}>
              Terms of Service
            </Typography>
            
            <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
              1. Acceptance of Terms
            </Typography>
            <Typography paragraph>
              By accessing and using our services, you agree to be bound by these Terms of Service
              and all applicable laws and regulations.
            </Typography>

            <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
              2. Use License
            </Typography>
            <Typography paragraph>
              Permission is granted to temporarily access the materials on Algofy's website for personal,
              non-commercial transitory viewing only.
            </Typography>

            <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
              3. Disclaimer
            </Typography>
            <Typography paragraph>
              The materials on Algofy's website are provided on an 'as is' basis. Algofy makes no
              warranties, expressed or implied, and hereby disclaims and negates all other warranties.
            </Typography>

            <Typography variant="body2" sx={{ mt: 6, color: 'text.secondary' }}>
              Last updated: {new Date().toLocaleDateString()}
            </Typography>
          </Paper>
        </motion.div>
      </Container>
    </Box>
  );
};

export default TermsOfService;