import React, { useState } from 'react';
import { Box, Container, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { motion } from 'framer-motion';
import ApplicationModal from '../../components/shared/ApplicationModal';
import VoiceMailApplication from '../Application/VoiceMailApplication';

const features = [
  'Hyper-personalized messaging',
  'AI-driven voice synthesis',
  'Automated campaign management',
  'Detailed delivery analytics',
  'High deliverability rates'
];

const VoiceMailPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Box sx={{ pb: 8 }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ textAlign: 'center', py: 8 }}>
            <Typography variant="h2" component="h1" gutterBottom>
              Hyper Personalized Ringless Voicemail
            </Typography>
            <Typography variant="h5" color="text.secondary" paragraph>
              Reach your customers effectively with personalized voicemail messages
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() => setIsModalOpen(true)}
              sx={{ mt: 4 }}
            >
              Schedule Demo
            </Button>
          </Box>

          <Box sx={{ py: 4 }}>
            {/* Reference existing VoiceMail component content */}
            <List>
              {features.map((feature) => (
                <ListItem key={feature}>
                  <ListItemIcon>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={feature} />
                </ListItem>
              ))}
            </List>
          </Box>
        </motion.div>
      </Container>

      <ApplicationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Voice Mail Integration Application"
      >
        <VoiceMailApplication />
      </ApplicationModal>
    </Box>
  );
};

export default VoiceMailPage; 