import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Button, 
  Grid, 
  Card, 
  CardContent, 
  Paper, 
  Avatar 
} from '@mui/material';
import { 
  Chat, 
  Timeline, 
  Security, 
  Language, 
  Psychology, 
  Storage, 
  Code, 
  Group, 
  FormatQuote 
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import ApplicationModal from '../../components/shared/ApplicationModal';
import ChatBotApplication from '../Application/ChatBotApplication';
import { Theme } from '@mui/material/styles';

interface Metric {
  value: string;
  label: string;
  prefix?: string;
  suffix?: string;
}

const caseStudyMetrics: Metric[] = [
  { value: '2,223', label: 'Questions Asked', suffix: '(44 days)' },
  { value: '50', label: 'Questions per day' },
  { value: '1,733', label: 'Hours saved per year' },
  { value: '52', prefix: '$', suffix: 'k', label: 'Annual savings' },
];

const competitiveAdvantages = [
  {
    title: 'Meticulous Content Indexing',
    description: 'Rigorous content verification to ensure error-free, accurate information'
  },
  {
    title: 'Antihallucination Assurance',
    description: 'Answers are supported with citations, enhancing reliability through referenced data'
  },
  {
    title: 'Financial Scalability',
    description: 'Cost-effective solutions designed to grow with your business needs'
  },
  {
    title: 'Data Ownership Guarantee',
    description: 'Full control over your proprietary data, ensuring privacy and exclusivity'
  }
];

const solutionSteps = [
  {
    title: 'Knowledge Base Creation',
    description: 'Construct a vector database containing videos, audios, images, documents, and PDFs.',
    icon: Storage
  },
  {
    title: 'LLM Model Development',
    description: 'Develop an LLM model (e.g. GPT-4) to emulate the style and responses of your subject matter experts.',
    icon: Code
  },
  {
    title: 'Team Access Via Slack/Web',
    description: 'Provide system access to the team through a Slack App, Chat Bubble or a web-based platform.',
    icon: Group
  }
];

const keyFeatures = [
  {
    title: 'Multi-Format Input Compatibility',
    description: 'Seamlessly process and analyze content from diverse file types, encompassing text documents (PDF, DOCX, TXT), and multimedia (audio, video, podcasts).',
    icon: Language
  },
  {
    title: 'Cross Referencing',
    description: 'Cross-reference information from various data sources or conversations to ensure responses are accurate and relevant.',
    icon: Timeline
  },
  {
    title: 'Style Matching',
    description: 'Adapt conversational style to match the tone and formality of the training data, ensuring natural and personalized interaction.',
    icon: Psychology
  },
  {
    title: 'Integration with Slack and Web',
    description: 'Seamlessly integrate with Slack and web platforms for direct user interaction without switching between applications.',
    icon: Chat
  }
];

// Update the testimonials type
interface Testimonial {
  id: number;
  name: string;
  role: string;
  comment: string;
  image: string;
}

const testimonials: Testimonial[] = [
  {
    id: 1,
    name: 'Robert Anderson',
    role: 'CEO, HealthCare Solutions Inc.',
    comment: 'The AI chatbot has transformed our customer service. We saved $52,000 annually in operational costs while achieving 80% faster response times. The custom knowledge base is incredibly accurate.',
    image: '/path/to/ceo-image.jpg',
  },
  {
    id: 2,
    name: 'Sarah Johnson',
    role: 'Head of Operations, HealthTech Inc.',
    comment: 'The AI chatbot has transformed how we handle internal knowledge sharing. Response times have decreased by 80%.',
    image: '/path/to/image1.jpg',
  },
  {
    id: 3,
    name: 'Michael Chen',
    role: 'CTO, Innovation Labs',
    comment: 'Impressive accuracy and response quality. The anti-hallucination features are game-changing.',
    image: '/path/to/image2.jpg',
  },
  {
    id: 4,
    name: 'Emily Rodriguez',
    role: 'Knowledge Manager',
    comment: 'Setup was quick, and the team support was exceptional.',
    image: '/path/to/image3.jpg',
  },
  {
    id: 5,
    name: 'David Kim',
    role: 'Director of Customer Success',
    comment: 'Our support team productivity increased by 60% within the first month.',
    image: '/path/to/image4.jpg',
  },
  {
    id: 6,
    name: 'Lisa Thompson',
    role: 'VP of Engineering',
    comment: 'The data privacy features and custom knowledge base integration exceeded our expectations.',
    image: '/path/to/image5.jpg',
  }
];

const ChatBotPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Box sx={{ pb: 8, width: '100%', overflow: 'hidden'}}>
      <Container 
        maxWidth="lg"
        sx={{ overflow: 'hidden' }} // Contain motion animations
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          style={{ overflow: 'hidden' }} // Contain motion animations
        >
          {/* 1. Hero Section */}
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              py: 1,
              gap: 8,
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: '-50%',
                right: '-50%',
                bottom: 0,
                background: (theme: Theme) => 
                  `linear-gradient(135deg, ${theme.palette.primary.main}15, ${theme.palette.secondary.main}10)`,
                zIndex: -1
              }
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Box sx={{ mb: 4 }}>
                <Typography
                  variant="overline"
                  sx={{
                    color: 'primary.main',
                    fontWeight: 'bold',
                    letterSpacing: 2,
                    mb: 2,
                    display: 'block'
                  }}
                >
                  ENTERPRISE AI SOLUTION
                </Typography>
                <Typography 
                  variant="h2" 
                  component="h1" 
                  gutterBottom
                  sx={{
                    fontWeight: 800,
                    background: (theme: Theme) => 
                      `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    mb: 3
                  }}
                >
                  AI-Powered Knowledge Base Assistant
                </Typography>
                <Typography 
                  variant="h4" 
                  sx={{ 
                    fontWeight: 'bold',
                    mb: 3,
                    color: 'text.primary'
                  }}
                >
                  Have Your Custom AI Knowledge Assistant in{' '}
                  <Box
                    component="span"
                    sx={{
                      color: 'primary.main',
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: -2,
                        left: 0,
                        width: '100%',
                        height: 4,
                        backgroundColor: 'primary.main',
                        opacity: 0.3,
                        borderRadius: 1
                      }
                    }}
                  >
                    7 Days
                  </Box>
                </Typography>
              </Box>

              <Typography 
                variant="h5" 
                sx={{ 
                  color: 'text.secondary',
                  lineHeight: 1.6,
                  mb: 4
                }}
              >
                Transform your organization's knowledge into an intelligent chatbot that understands{' '}
                <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                  multiple content formats
                </Box>{' '}
                - including{' '}
                <Box component="span" sx={{ color: 'text.primary', fontWeight: 500 }}>
                  videos, audio files, documents, and images
                </Box>. 
                Get accurate, context-aware responses while maintaining data privacy and security.
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4 }}>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    color: 'text.secondary',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    position: 'relative'
                  }}
                >
                  Save{' '}
                  <Box
                    component="span"
                    sx={{
                      fontSize: '2.5rem',
                      fontWeight: 'bold',
                      background: (theme: Theme) => 
                        `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    $50K
                  </Box>
                  {' '}annually
                  <Box
                    component="span"
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      ml: 2,
                      width: '80px',
                      height: '80px',
                      transform: 'rotate(-15deg)',
                      border: '2px solid',
                      borderColor: 'success.main',
                      borderRadius: '50%',
                      color: 'success.main',
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      position: 'relative',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: -2,
                        left: -2,
                        right: -2,
                        bottom: -2,
                        border: '2px dashed',
                        borderColor: 'success.light',
                        borderRadius: '50%',
                      }
                    }}
                  >
                    PROVEN!
                  </Box>
                </Typography>
              </Box>

              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 'medium',
                  color: 'text.secondary',
                  mb: 4
                }}
              >
                Ready to Transform Your Team with a Done-For-You AI Solution?
              </Typography>

              <Button
                variant="contained"
                size="large"
                onClick={() => setIsModalOpen(true)}
                sx={{
                  py: { xs: 2, md: 3 },
                  px: { xs: 6, md: 8 },
                  fontSize: { xs: '1.5rem', md: '1.75rem' },
                  fontWeight: 700,
                  textTransform: 'uppercase',  // Changed to uppercase
                  letterSpacing: '0.1em',      // Added letter spacing for better readability
                  borderRadius: 3,
                  minWidth: { xs: '240px', md: '300px' },
                  boxShadow: (theme) => `0 8px 32px ${theme.palette.primary.main}40`,
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: (theme) => `0 12px 40px ${theme.palette.primary.main}60`,
                    transition: 'all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)'
                  },
                  transition: 'all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)'
                }}
              >
                Schedule Demo
              </Button>
            </Box>

            <Box 
              sx={{ 
                flex: 1,
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '120%',
                  height: '120%',
                  background: (theme: Theme) => 
                    `radial-gradient(circle, ${theme.palette.primary.main}15 0%, transparent 70%)`,
                  zIndex: -1
                }
              }}
            >
              <Box
                component="img"
                src="/images/chatbot-hero.png"
                srcSet="/images/chatbot-hero.png 1x, /images/chatbot-hero@2x.png 2x"
                alt="AI Chatbot Assistant"
                sx={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: 500,
                  objectFit: 'contain',
                  borderRadius: 4,
                  boxShadow: 4,
                  transform: 'perspective(1000px) rotateY(-5deg)',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'perspective(1000px) rotateY(0deg)',
                  }
                }}
              />
            </Box>
          </Box>

          {/* 2. Solution Steps */}
          <Box sx={{ py: 8 }}>
            <Typography 
              variant="h3" 
              gutterBottom 
              align="center"
              sx={{
                mb: 6,
                fontWeight: 'bold',
                background: (theme: Theme) => 
                  `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Implementation Process
            </Typography>
            <Box sx={{ position: 'relative', mt: 6 }}>
              {/* Connection Line */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '10%',
                  right: '10%',
                  height: 3,
                  bgcolor: 'primary.main',
                  opacity: 0.3,
                  display: { xs: 'none', md: 'block' }
                }}
              />
              
              <Grid container spacing={4}>
                {solutionSteps.map((step, index) => (
                  <Grid item xs={12} md={4} key={step.title}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.2 }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          textAlign: 'center',
                          p: 3
                        }}
                      >
                        {/* Step Number Circle */}
                        <Box
                          sx={{
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            bgcolor: 'primary.main',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            position: 'absolute',
                            top: 0,
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2,
                            boxShadow: 3
                          }}
                        >
                          {index + 1}
                        </Box>

                        {/* Icon Circle */}
                        <Box
                          sx={{
                            width: 120,
                            height: 120,
                            borderRadius: '50%',
                            bgcolor: 'background.paper',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '0 auto 2rem',
                            boxShadow: 3,
                            position: 'relative',
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              top: -2,
                              left: -2,
                              right: -2,
                              bottom: -2,
                              borderRadius: '50%',
                              background: (theme: Theme) => 
                                `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                              zIndex: -1
                            }
                          }}
                        >
                          <step.icon
                            sx={{
                              fontSize: 50,
                              color: 'primary.main'
                            }}
                          />
                        </Box>

                        {/* Content */}
                        <Box
                          sx={{
                            bgcolor: 'background.paper',
                            borderRadius: 2,
                            p: 3,
                            minHeight: 200,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            boxShadow: 3,
                            transition: 'transform 0.2s ease-in-out',
                            '&:hover': {
                              transform: 'translateY(-8px)'
                            }
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              mb: 2,
                              background: (theme: Theme) => 
                                `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent'
                            }}
                          >
                            {step.title}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{ lineHeight: 1.7 }}
                          >
                            {step.description}
                          </Typography>
                        </Box>
                      </Box>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>

          {/* 3. How It Works Blueprint */}
          {/* <Box sx={{ py: 8 }}>
            <Typography variant="h3" gutterBottom align="center">
              How It Works
            </Typography>
            <Grid container spacing={4} sx={{ mt: 4 }}>
              <Grid item xs={12} md={6}>
                <Box>
                  <List>
                    {[
                      {
                        title: 'Data Acquisition',
                        description: 'Diverse data types, such as documents, PDFs, audio, and video, are continuously gathered into an ever-expanding repository.'
                      },
                      {
                        title: 'Data Processing',
                        description: 'The collected data is transformed by a data processor, which involves vectorizing and indexing. This step prepares the data for efficient retrieval and analysis.'
                      },
                      {
                        title: 'Knowledge Base Formation',
                        description: 'The processed data is stored in a structured knowledge base, facilitating easy access and retrieval.'
                      },
                      {
                        title: 'Custom AI Model Integration',
                        description: 'A custom-trained AI model, based on sophisticated reasoning and structuring techniques, interacts with the knowledge base. This model is optimized for understanding and matching queries with the stored information.'
                      },
                      {
                        title: 'Communication Platforms',
                        description: 'The AI model integrates seamlessly with communication platforms (e.g., web interfaces, chat applications) where users can interact through inputs and responses.'
                      },
                      {
                        title: 'User Interaction',
                        description: 'Users engage with the chatbot, which processes their queries, accesses relevant information from the knowledge base, and provides accurate, context-driven responses.'
                      }
                    ].map((step, index) => (
                      <ListItem
                        key={step.title}
                        sx={{
                          display: 'block',
                          mb: 3,
                          '&:last-child': { mb: 0 }
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            color="primary"
                            sx={{ mr: 2, minWidth: '28px' }}
                          >
                            {(index + 1).toString().padStart(2, '0')}
                          </Typography>
                          <Typography variant="h6" component="span">
                            {step.title}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ pl: '44px' }}
                        >
                          {step.description}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                  <Typography
                    variant="body1"
                    sx={{ mt: 4, fontStyle: 'italic' }}
                    color="text.secondary"
                  >
                    By employing this framework, a highly efficient and intelligent chatbot is crafted, 
                    capable of delivering precise information and solutions to client queries across 
                    various platforms.
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper 
                  sx={{ 
                    p: 4, 
                    height: '100%',
                    minHeight: 600,
                    bgcolor: 'grey.100',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography align="center">Blueprint Image Placeholder</Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box> */}

          {/* 4. Key Features */}
          <Box sx={{ py: 8 }}>
            <Typography 
              variant="h3" 
              gutterBottom 
              align="center"
              sx={{
                mb: 6,
                fontWeight: 'bold',
                background: (theme: Theme) => 
                  `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Key Features
            </Typography>
            <Grid container spacing={4}>
              {keyFeatures.map((feature) => (
                <Grid item xs={12} sm={6} key={feature.title}>
                  <Card 
                    sx={{ 
                      height: '100%',
                      transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow: (theme) => theme.shadows[8],
                      },
                    }}
                  >
                    <CardContent sx={{ p: 4 }}>
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          mb: 3,
                          gap: 2 
                        }}
                      >
                        <Box
                          sx={{
                            bgcolor: 'primary.main',
                            borderRadius: '12px',
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <feature.icon 
                            sx={{ 
                              fontSize: 40, 
                              color: 'white'
                            }} 
                          />
                        </Box>
                        <Typography 
                          variant="h5" 
                          sx={{ 
                            fontWeight: 'bold',
                            color: 'text.primary'
                          }}
                        >
                          {feature.title}
                        </Typography>
                      </Box>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          color: 'text.secondary',
                          fontSize: '1.1rem',
                          lineHeight: 1.7
                        }}
                      >
                        {feature.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* 5. Demo Section */}
          <Box sx={{ py: 8 }}>
            <Typography 
              variant="h3" 
              gutterBottom 
              align="center"
              sx={{
                mb: 6,
                fontWeight: 'bold',
                background: (theme: Theme) => 
                  `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              See It In Action
            </Typography>
            <Paper 
              sx={{ 
                p: 4, 
                mt: 4,
                bgcolor: 'grey.100',
                overflow: 'hidden',
                maxWidth: '1600px',
                mx: 'auto',
                position: 'relative',
                paddingTop: '56.25%' // 16:9 aspect ratio
              }}
            >
              <Box
                component="iframe"
                src="https://www.youtube-nocookie.com/embed/wD-3q_OVozA?autoplay=1&mute=1&controls=0&loop=1&modestbranding=1&showinfo=0&rel=0"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 0
                }}
                allow="autoplay; encrypted-media"
                allowFullScreen
              />
            </Paper>
          </Box>

          {/* 6. Testimonials */}
          <Box sx={{ py: 8 }}>
            <Typography 
              variant="h3" 
              gutterBottom 
              align="center"
              sx={{
                mb: 6,
                fontWeight: 'bold',
                background: (theme: Theme) => 
                  `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              What Our Clients Say
            </Typography>
            
            <Grid container spacing={4} sx={{ mt: 4 }}>
              {testimonials.map((testimonial) => (
                <Grid item xs={12} md={4} key={testimonial.id}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: testimonial.id * 0.1 }}
                  >
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        overflow: 'hidden',
                        background: (theme) => `linear-gradient(145deg, 
                          ${theme.palette.background.paper} 0%,
                          ${theme.palette.background.default} 100%)`,
                        boxShadow: (theme) => `0 8px 32px ${theme.palette.primary.main}15`,
                        transition: 'transform 0.2s ease-in-out',
                        '&:hover': {
                          transform: 'translateY(-8px)',
                        }
                      }}
                    >
                      <CardContent
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                          p: 4
                        }}
                      >
                        {/* Quote Content */}
                        <Box sx={{ flex: 1 }}>
                          <FormatQuote
                            sx={{
                              fontSize: 40,
                              color: 'primary.main',
                              opacity: 0.2,
                              mb: 2
                            }}
                          />
                          
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: '1.1rem',
                              lineHeight: 1.8,
                              color: 'text.primary',
                              '& mark': {
                                backgroundColor: 'transparent',
                                color: 'primary.main',
                                fontWeight: 'bold',
                              }
                            }}
                            dangerouslySetInnerHTML={{
                              __html: testimonial.comment.replace(
                                /(saved \$52,000|80%|60%|ROI|efficiency|accuracy|response times|data privacy|custom knowledge base)/gi,
                                match => `<mark>${match}</mark>`
                              )
                            }}
                          />
                        </Box>

                        {/* Author Info - Always at bottom */}
                        <Box 
                          sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            borderTop: '1px solid',
                            borderColor: 'divider',
                            mt: 4,
                            pt: 3
                          }}
                        >
                          <Avatar
                            src={testimonial.image}
                            sx={{
                              width: 56,
                              height: 56,
                              mr: 2,
                              border: '2px solid',
                              borderColor: 'primary.main'
                            }}
                          />
                          <Box>
                            <Typography 
                              variant="h6" 
                              sx={{ 
                                fontWeight: 'bold',
                                color: 'text.primary'
                              }}
                            >
                              {testimonial.name}
                            </Typography>
                            <Typography 
                              variant="subtitle2" 
                              sx={{ 
                                color: 'text.secondary',
                                fontWeight: 'medium'
                              }}
                            >
                              {testimonial.role}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* 7. Metrics */}
          <Box 
            sx={{ 
              py: 8,
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: '-50%',
                right: '-50%',
                bottom: 0,
                background: (theme) => 
                  `linear-gradient(135deg, ${theme.palette.primary.main}08, ${theme.palette.secondary.main}08)`,
                zIndex: -1
              }
            }}
          >
            <Typography 
              variant="h3" 
              align="center" 
              gutterBottom
              sx={{ 
                mb: 3,
                fontWeight: 'bold',
                background: (theme) => 
                  `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              ROI That Makes Implementation a No-Brainer
            </Typography>
            
            <Typography 
              variant="h6" 
              align="center" 
              color="text.secondary" 
              sx={{ mb: 6, maxWidth: '800px', mx: 'auto' }}
            >
              Our clients experience rapid returns on their investment through improved efficiency, 
              reduced response times, and significant cost savings.
            </Typography>

            <Grid container spacing={3} sx={{ mb: 6 }}>
              {caseStudyMetrics.map((metric, index) => (
                <Grid item xs={12} sm={6} md={3} key={metric.label}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <Card 
                      elevation={0}
                      sx={{
                        height: '100%',
                        p: 3,
                        textAlign: 'center',
                        position: 'relative',
                        overflow: 'hidden',
                        background: 'transparent',
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          background: (theme) => 
                            `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
                          borderRadius: 2,
                          border: '1px solid',
                          borderColor: 'divider',
                          zIndex: -1
                        },
                        '&:hover': {
                          transform: 'translateY(-8px)',
                          transition: 'transform 0.3s ease-in-out',
                          '& .metric-value': {
                            transform: 'scale(1.1)',
                          }
                        }
                      }}
                    >
                      <Typography 
                        variant="h3" 
                        className="metric-value"
                        sx={{ 
                          mb: 1,
                          fontWeight: 'bold',
                          color: 'primary.main',
                          transition: 'transform 0.3s ease-in-out',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 1
                        }}
                      >
                        {metric.prefix && (
                          <Box component="span" sx={{ fontSize: '0.7em', opacity: 0.8 }}>
                            {metric.prefix}
                          </Box>
                        )}
                        {metric.value}
                        {metric.suffix && (
                          <Box component="span" sx={{ fontSize: '0.7em', opacity: 0.8 }}>
                            {metric.suffix}
                          </Box>
                        )}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          color: 'text.secondary',
                          fontWeight: 500,
                          fontSize: '1.1rem'
                        }}
                      >
                        {metric.label}
                      </Typography>
                    </Card>
                  </motion.div>
                </Grid>
              ))}
            </Grid>

            <Box 
              sx={{ 
                maxWidth: '800px', 
                mx: 'auto',
                p: 4,
                borderRadius: 2,
                bgcolor: 'background.paper',
                boxShadow: 2,
                border: '1px solid',
                borderColor: 'divider'
              }}
            >
              <Typography 
                variant="h5" 
                sx={{ 
                  mb: 2,
                  fontWeight: 'bold',
                  color: 'primary.main'
                }}
              >
                Real Results from Real Clients
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary', lineHeight: 1.8 }}>
                Our healthcare provider clients have reported average savings of{' '}
                <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                  $52,000 annually
                </Box>{' '}
                in operational costs. When you combine{' '}
                <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                  reduced response times
                </Box>,{' '}
                <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                  24/7 availability
                </Box>, and{' '}
                <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                  improved team efficiency
                </Box>, the decision to implement becomes clear.
              </Typography>
            </Box>
          </Box>

          {/* Competitive Advantages */}
          <Box sx={{ py: 8 }}>
            <Typography 
              variant="h3" 
              align="center" 
              gutterBottom
              sx={{
                mb: 6,
                fontWeight: 'bold',
                background: (theme) => 
                  `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Why Choose Our Solution
            </Typography>

            <Box 
              sx={{ 
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                gap: 4,
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  left: '0',
                  right: '0',
                  height: '1px',
                  background: (theme) => theme.palette.divider,
                  display: { xs: 'none', md: 'block' }
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: '0',
                  left: '50%',
                  bottom: '0',
                  width: '1px',
                  background: (theme) => theme.palette.divider,
                  display: { xs: 'none', md: 'block' }
                }
              }}
            >
              {competitiveAdvantages.map((advantage, index) => (
                <motion.div
                  key={advantage.title}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Box
                    sx={{
                      p: 4,
                      height: '100%',
                      position: 'relative',
                      '&:hover': {
                        '& .advantage-icon': {
                          transform: 'scale(1.1) rotate(5deg)',
                          color: 'primary.main',
                        },
                        '& .advantage-number': {
                          opacity: 0.15,
                        }
                      }
                    }}
                  >
                    <Box
                      className="advantage-number"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        fontSize: '8rem',
                        fontWeight: 'bold',
                        opacity: 0.05,
                        transition: 'opacity 0.3s ease-in-out',
                        lineHeight: 1,
                        color: 'primary.main',
                      }}
                    >
                      {(index + 1).toString().padStart(2, '0')}
                    </Box>

                    <Box
                      className="advantage-icon"
                      sx={{
                        width: 80,
                        height: 80,
                        borderRadius: '20px',
                        bgcolor: (theme) => theme.palette.primary.main + '08',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 3,
                        transition: 'all 0.3s ease-in-out',
                      }}
                    >
                      {index === 0 && <Storage sx={{ fontSize: 40, color: 'primary.main' }} />}
                      {index === 1 && <Psychology sx={{ fontSize: 40, color: 'primary.main' }} />}
                      {index === 2 && <Timeline sx={{ fontSize: 40, color: 'primary.main' }} />}
                      {index === 3 && <Security sx={{ fontSize: 40, color: 'primary.main' }} />}
                    </Box>

                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontWeight: 'bold',
                        background: (theme) => 
                          `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        mb: 2
                      }}
                    >
                      {advantage.title}
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        color: 'text.secondary',
                        fontSize: '1.1rem',
                        lineHeight: 1.7,
                      }}
                    >
                      {advantage.description}
                    </Typography>
                  </Box>
                </motion.div>
              ))}
            </Box>
          </Box>
        </motion.div>
      </Container>

      <ApplicationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Chat Bot Integration Application"
      >
        <ChatBotApplication />
      </ApplicationModal>
    </Box>
  );
};

export default ChatBotPage; 