import React from 'react';
import { Paper, Typography, Box, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { CheckCircle, Chat } from '@mui/icons-material';
import { motion } from 'framer-motion';

const features = [
  'Enterprise data integration',
  'Advanced AI understanding',
  'Secure data handling',
  'Custom knowledge base',
  'Multi-language support'
];

const ChatBot: React.FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Paper sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Chat sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
          <Typography variant="h4" component="h2">
            Custom Chat Bot Layer
          </Typography>
        </Box>
        
        <Typography variant="body1" paragraph>
          Deploy an intelligent chatbot that seamlessly integrates with your enterprise 
          data. Our solution provides secure, accurate, and context-aware responses 
          while maintaining data privacy.
        </Typography>

        <List>
          {features.map((feature) => (
            <ListItem key={feature}>
              <ListItemIcon>
                <CheckCircle color="primary" />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>

        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button
            variant="contained"
            size="large"
            href="/services/chat-bot"
            sx={{ minWidth: 200 }}
          >
            Learn More
          </Button>
        </Box>
      </Paper>
    </motion.div>
  );
};

export default ChatBot; 