import React, { useState } from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { motion } from 'framer-motion';
import ApplicationModal from '../../components/shared/ApplicationModal';
import LeadScoringApplication from '../Application/LeadScoringApplication';

const LeadScoringPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <Box sx={{ pb: 8 }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Hero Section */}
          <Box sx={{ textAlign: 'center', py: 8 }}>
            <Typography variant="h2" component="h1" gutterBottom>
              Lead Scoring Integration
            </Typography>
            <Typography variant="h5" color="text.secondary" paragraph>
              Transform your lead qualification process with AI-powered scoring
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={handleOpenModal}
              sx={{ mt: 4 }}
            >
              Get Started
            </Button>
          </Box>

          {/* Content from LeadScoring component */}
          <Box sx={{ py: 4 }}>
            {/* Reference existing LeadScoring component content */}
            {/* Lines 29-44 from src/pages/Services/components/LeadScoring.tsx */}
          </Box>
        </motion.div>
      </Container>

      <ApplicationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        title="Lead Scoring Integration Application"
      >
        <LeadScoringApplication />
      </ApplicationModal>
    </Box>
  );
};

export default LeadScoringPage; 