 import React from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import { Speed, Security, EmojiObjects, Psychology } from '@mui/icons-material';
import { motion } from 'framer-motion';

const benefits = [
  {
    icon: Speed,
    title: 'Fast Implementation',
    description: 'Get your AI solutions up and running quickly with our streamlined process.',
  },
  {
    icon: Security,
    title: 'Enterprise Security',
    description: 'Bank-grade security measures to protect your sensitive data.',
  },
  {
    icon: EmojiObjects,
    title: 'Innovation First',
    description: 'Stay ahead with cutting-edge AI technologies and methodologies.',
  },
  {
    icon: Psychology,
    title: 'Expert Team',
    description: 'Work with experienced AI specialists and data scientists.',
  },
];

const WhyChooseUs: React.FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Typography
        component="h2"
        variant="h3"
        align="center"
        color="text.primary"
        gutterBottom
        sx={{ mb: 6 }}
      >
        Why Choose Algofy.ai
      </Typography>
      <Grid container spacing={4}>
        {benefits.map((benefit, index) => (
          <Grid item xs={12} sm={6} key={benefit.title}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <Paper
                sx={{
                  p: 3,
                  height: '100%',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    transition: 'transform 0.3s ease-in-out',
                  },
                }}
                elevation={2}
              >
                <benefit.icon
                  sx={{ fontSize: 40, color: 'primary.main', mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  {benefit.title}
                </Typography>
                <Typography color="text.secondary">
                  {benefit.description}
                </Typography>
              </Paper>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WhyChooseUs;