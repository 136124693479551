import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/Home/HomePage';
import ServicesPage from './pages/Services/ServicesPage';
import LeadScoringPage from './pages/Services/LeadScoringPage';
import VoiceMailPage from './pages/Services/VoiceMailPage';
import ChatBotPage from './pages/Services/ChatBotPage';
import ChatBotLiveChat from './pages/Services/ChatBotLiveChat';
import ContactPage from './pages/Contact/ContactPage';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import TermsOfService from './pages/Legal/TermsOfService';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/services/lead-scoring" element={<LeadScoringPage />} />
      <Route path="/services/voice-mail" element={<VoiceMailPage />} />
      <Route path="/services/chat-bot" element={<ChatBotPage />} />
      <Route path="/services/chat-bot-live-chat" element={<ChatBotLiveChat />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
    </Routes>
  );
};