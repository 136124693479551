import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <Box 
      component="footer"
      sx={{ 
        bgcolor: 'background.paper',
        py: 8,
        borderTop: '1px solid',
        borderColor: 'divider',
        mt: 8
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Company Info */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              Algofy
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              10900 Research Blvd, Ste 160C<br />
              Austin, TX 78759
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary" 
              sx={{ 
                display: 'flex',
                flexDirection: 'column',
                gap: 1
              }}
            >
              <Box component="a" href="tel:+15126664243" sx={{ color: 'inherit', textDecoration: 'none' }}>
                (512) 666-4243
              </Box>
              <Box component="a" href="mailto:support@algofy.ai" sx={{ color: 'inherit', textDecoration: 'none' }}>
                support@algofy.ai
              </Box>
            </Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              Quick Links
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {['Services', 'Contact'].map((link) => (
                <Typography
                  key={link}
                  variant="body2"
                  component="a"
                  href={`/${link.toLowerCase().replace(/\s+/g, '-')}`}
                  sx={{
                    color: 'text.secondary',
                    textDecoration: 'none',
                    '&:hover': {
                      color: 'primary.main',
                    }
                  }}
                >
                  {link}
                </Typography>
              ))}
            </Box>
          </Grid>

          {/* Legal & Social */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              Legal
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 3 }}>
              {['Privacy Policy', 'Terms of Service'].map((link) => (
                <Typography
                  key={link}
                  variant="body2"
                  component={Link}
                  to={`/${link.toLowerCase().replace(/\s+/g, '-')}`}
                  sx={{
                    color: 'text.secondary',
                    textDecoration: 'none',
                    '&:hover': {
                      color: 'primary.main',
                    }
                  }}
                >
                  {link}
                </Typography>
              ))}
            </Box>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Box 
          sx={{ 
            pt: 4, 
            mt: 4, 
            borderTop: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Algofy. All rights reserved
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Made with ❤️ by HandL Digital
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer; 