import { Box, Container, Typography, Paper } from '@mui/material';
import { motion } from 'framer-motion';

const PrivacyPolicy: React.FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Paper sx={{ p: 4 }}>
            <Typography variant="h3" gutterBottom align="center" sx={{ mb: 6 }}>
              Privacy Policy
            </Typography>
            
            <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
              1. Information We Collect
            </Typography>
            <Typography paragraph>
              We collect information that you provide directly to us, including when you create an account,
              make a purchase, sign up for our newsletter, or contact us for support.
            </Typography>

            <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
              2. How We Use Your Information
            </Typography>
            <Typography paragraph>
              We use the information we collect to provide, maintain, and improve our services,
              to communicate with you, and to personalize your experience.
            </Typography>

            <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
              3. Data Security
            </Typography>
            <Typography paragraph>
              We implement appropriate technical and organizational measures to protect your personal data
              against unauthorized or unlawful processing, accidental loss, destruction, or damage.
            </Typography>

            <Typography variant="body2" sx={{ mt: 6, color: 'text.secondary' }}>
              Last updated: {new Date().toLocaleDateString()}
            </Typography>
          </Paper>
        </motion.div>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;