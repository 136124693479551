import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useUTMTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.HandL && typeof window.HandL.sendEvent === 'function') {
      window.HandL.sendEvent();
    }
  }, [location]);
};
