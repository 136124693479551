import { useState } from 'react';
import { toast } from 'react-toastify';

interface FormSubmissionOptions {
  successMessage?: string;
  errorMessage?: string;
  endpoint: string;
  onSuccess?: () => void;
}

export const useFormSubmission = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (formData: any, options: FormSubmissionOptions) => {
    setIsSubmitting(true);

    try {
      const response = await fetch(options.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success(options.successMessage || 'Form submitted successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        
        if (options.onSuccess) {
          options.onSuccess();
        }
      } else {
        throw new Error('Submission failed');
      }
    } catch (error) {
      toast.error(options.errorMessage || 'Failed to submit form. Please try again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return { isSubmitting, handleSubmit };
}; 