import React from 'react';
import { Box, Container } from '@mui/material';
import HeroSection from './components/HeroSection'
import ServicesOverview from './components/ServicesOverview'
import WhyChooseUs from './components/WhyChooseUs'
import Testimonials from './components/Testimonials'
import CallToAction from './components/CallToAction'

const HomePage: React.FC = () => {
  return (
    <Box>
      <HeroSection />
      <Container maxWidth="lg">
        <ServicesOverview />
        <WhyChooseUs />
        <Testimonials />
        <CallToAction />
      </Container>
    </Box>
  );
};

export default HomePage; 