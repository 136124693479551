import React, { useEffect, useRef } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const ChatBotLiveChat: React.FC = () => {
  const scriptLoaded = useRef(false);

  useEffect(() => {
    if (scriptLoaded.current) {
      return;
    }

    const chatConfig = {
      license_key: "b1bae7b0-7b7b-4b7b-8b7b-9b7b0b7b0b7c",
      chat_title: "Algofy Intelligent Demo",
      chat_welcome_message: "Hey 👋",
      chat_endpoint: "https://scfpdzrr6gtcjhkz5332ao27v40bqltn.lambda-url.us-west-1.on.aws",
      embed_type: 'iframe',
      targetID: 'algofy-chatbot-container'
    };

    Object.assign(window, chatConfig);
    const MyScript = document.createElement('script');
    // MyScript.src = 'http://localhost/test/chat-widget/dist/handl-chat-botui.js';
    MyScript.src = 'https://botui.handldigital.com/handl-chat-botui.js';
    document.head.appendChild(MyScript);
    
    scriptLoaded.current = true;

    return () => {
      if (MyScript && MyScript.parentNode) {
        MyScript.parentNode.removeChild(MyScript);
        scriptLoaded.current = false;
      }
    };
  }, []);

  return (
    <Box sx={{ pb: 8, width: '100%', overflow: 'hidden' }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ textAlign: 'center', py: 8 }}>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{
                fontWeight: 800,
                mb: 4
              }}
            >
              Experience Our AI Chat Bot
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
                mb: 4,
                lineHeight: 1.8
              }}
            >
              Why read about it when you can{' '}
              <Box component="span" sx={{ 
                color: 'primary.main', 
                fontWeight: 'bold',
                textTransform: 'uppercase',
                letterSpacing: '0.5px'
              }}>
                EXPERIENCE IT FIRSTHAND
              </Box>? 
              Interact with our Algofy Chat Bot below to {' '}
              <Box component="span" sx={{ 
                color: 'text.primary',
                fontWeight: 600
              }}>
                see if you're a good fit for this solution
              </Box>{' '}
              and schedule your call with us. See how seamlessly we can help you create a chat bot tailored to{' '}
              <Box component="span" sx={{ 
                background: (theme) => 
                  `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 'bold'
              }}>
                YOUR PROPRIETARY DATA
              </Box>. 
              <br/><br/>Transform your customer interactions with a{' '}
              <Box component="span" sx={{ 
                color: 'primary.main',
                fontWeight: 'bold'
              }}>
                tailored solution designed just for you
              </Box>.
            </Typography>
          </Box>

          <Box
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              borderRadius: 2,
              p: 2,
              height: '800px',
              '& .chat-iframe-wrapper': {
                height: '100% !important',
                marginBottom: '0 !important',
                '& iframe': {
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  maxWidth: '100%'
                }
              }
            }}
            id="algofy-chatbot-container"
          >
            {/* Chat bot script will be inserted here */}
          </Box>

          <Box
            sx={{
              mt: 12,
              mb: 8,
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
              gap: 4,
              '& img': {
                width: '100%',
                height: 'auto',
                borderRadius: 2,
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                cursor: 'pointer',
                boxShadow: (theme) => `0 4px 20px ${theme.palette.primary.main}15`,
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: (theme) => `0 12px 40px ${theme.palette.primary.main}25`,
                }
              }
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7].map((num, index) => (
              <motion.div
                key={num}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <img 
                  src={`/images/testimonials/algofy-testo${num}.png`} 
                  alt={`Client Testimonial ${num}`}
                  loading="lazy"
                />
              </motion.div>
            ))}
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};

export default ChatBotLiveChat; 