import React from 'react';
import { Grid, Card, CardContent, Typography, CardActions } from '@mui/material';
import { AutoGraph, Psychology, Storage } from '@mui/icons-material';

const services = [
  {
    title: 'Data Analytics',
    description: 'Transform your raw data into actionable insights with our advanced analytics solutions.',
    icon: AutoGraph,
  },
  {
    title: 'Machine Learning',
    description: 'Implement cutting-edge ML models to automate and optimize your business processes.',
    icon: Psychology,
  },
  {
    title: 'Data Infrastructure',
    description: 'Build robust and scalable data infrastructure to support your AI initiatives.',
    icon: Storage,
  },
];

const ServicesOverview: React.FC = () => {
  return (
    <Grid container spacing={4} sx={{ py: 8 }}>
      {services.map((service) => (
        <Grid item xs={12} sm={6} md={4} key={service.title}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              '&:hover': {
                transform: 'translateY(-4px)',
                transition: 'transform 0.3s ease-in-out',
              },
            }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <service.icon sx={{ fontSize: 48, mb: 2, color: 'primary.main' }} />
              <Typography gutterBottom variant="h5" component="h2">
                {service.title}
              </Typography>
              <Typography>{service.description}</Typography>
            </CardContent>
            <CardActions>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ServicesOverview;